import { useContext } from 'react';
import { BreakpointsConfigMatch } from '../../../constants/breakpoints';
import MatchMediaContext from 'react-match-media-context';
import useToggleVisibility from '../../../hooks/useToggleVisibility';
import { isBrowser } from '../../../utility/environmentUtilities';

export default function usePlatformFilteringVisibility() {
    const matchingBreakpoints = useContext<BreakpointsConfigMatch>(
        MatchMediaContext
    );
    const isSmallDevice =
        isBrowser &&
        (matchingBreakpoints.xs ||
            matchingBreakpoints.sm ||
            matchingBreakpoints.md);

    const { isVisible, toggleVisibility } = useToggleVisibility(!isSmallDevice);

    return { isVisible, toggleVisibility };
}
