import { Query } from './../../../graphql';
import { SectorFilterParentSector } from '../../../model/types/sectorFilterTypes';
import { useStaticQuery, graphql } from 'gatsby';
import { createSortByItemKey } from '../../../utility/arrayUtilities';

export default function usePlatformSectors() {
    const sectorData: Query = useStaticQuery(graphql`
        {
            allContentfulParentSector(sort: { order: ASC, fields: title }) {
                nodes {
                    id
                    title
                    sector {
                        id
                        slug
                        title
                        contentful_id
                    }
                }
            }
        }
    `);

    const parentSectors = sectorData.allContentfulParentSector
        .nodes as SectorFilterParentSector[];

    const platformSectors: SectorFilterParentSector[] = parentSectors
        .filter((parentSector) => parentSector.sector.length > 0)
        .sort(createSortByItemKey<SectorFilterParentSector>('title'))
        .map((parentSector) => {
            const sortedChildSectors = parentSector.sector.sort(
                (first, second) => {
                    if (first.title === 'Overig') {
                        return 1;
                    }

                    if (second.title === 'Overig') {
                        return -1;
                    }

                    return first.title.localeCompare(second.title);
                }
            );

            return { ...parentSector, sector: sortedChildSectors };
        });

    return {
        platformSectors,
    };
}
