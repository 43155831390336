import { PlatformOverviewSector } from './../../../model/types/platformOverviewTypes.d';

type GroupedSectorsType = {
    [key: string]: string[];
};

export function selectNestedSectorTitles(
    sectors: PlatformOverviewSector[]
): GroupedSectorsType {
    return sectors.reduce<GroupedSectorsType>((groupedSectors, sector) => {
        if (typeof sector === 'undefined') {
            return groupedSectors;
        }

        const { title } = sector.parentSector;

        if (typeof groupedSectors[title] === 'undefined') {
            groupedSectors[title] = [];
        }

        groupedSectors[title].push(sector.title);

        return groupedSectors;
    }, {});
}
