import React from 'react';
import Heading, { TagOptions, WeightOptions } from '../heading/Heading';
import styles from './spotlight.module.scss';
import SpotlightImageUrl from '../../../images/illustratie-home.svg';
import { ReactComponent as DownloadIcon } from '../../../images/icons/download.svg';
import createClassname from 'classnames';

// @ts-ignore -> typescript does not read json file
import { fileName as exportFileName } from '../../../databaseExport.json';

type Props = {
    title: string;
    subTitle: string;
};

const Spotlight = ({ title, subTitle }: Props) => {
    const linkClassName = createClassname(
        styles.downloadButton,
        'button--primary'
    );

    console.log('export file name', exportFileName);

    return (
        <div className="container">
            <div className={styles.spotlight}>
                <div className={styles.spotlightContent}>
                    <div>
                        <Heading
                            tag={TagOptions.h1}
                            weight={WeightOptions.extraBold}
                            flatten
                        >
                            {title}
                        </Heading>

                        <p className={styles.spotlightSubscript}>{subTitle}</p>

                        {exportFileName && (
                            <a
                                className={linkClassName}
                                href={`/${exportFileName}`}
                                title="Download database"
                                download
                            >
                                <DownloadIcon className={styles.icon} />
                                Download database
                            </a>
                        )}
                    </div>
                </div>

                <div className={styles.spotlightImagecontainer}>
                    <img
                        className={styles.spotlightImage}
                        src={SpotlightImageUrl}
                        alt="header illustratie"
                    />
                </div>
            </div>
        </div>
    );
};

export default Spotlight;
