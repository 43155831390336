import React, { Fragment } from 'react';
import { PlatformOverviewSector } from '../../../model/types/platformOverviewTypes';
import styles from '../platformOverview.module.scss';
import { selectNestedSectorTitles } from '../utilities/sectorSelectors';

type Props = {
    sectors: PlatformOverviewSector[];
};

const PlatformOverviewSectorList: React.FC<Props> = ({ sectors }) => {
    const nestedSectorTitles = selectNestedSectorTitles(sectors);

    return (
        <>
            {Object.keys(nestedSectorTitles).map((parentSector) => (
                <Fragment key={parentSector}>
                    <span className={styles.parentSector}>{parentSector}</span>
                    {nestedSectorTitles[parentSector].join(', ')}
                </Fragment>
            ))}
        </>
    );
};

export default PlatformOverviewSectorList;
