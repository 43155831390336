import React, { ReactNode } from 'react';
import styles from '../platformOverview.module.scss';

type Props = {
    children: ReactNode;
};

const PlatformOverviewList = ({ children }: Props) => (
    <div className={styles.list}>{children}</div>
);

export default PlatformOverviewList;
