import React, { Fragment } from 'react';
import Heading, {
    SizeVariationOptions,
    StyleOptions,
    TagOptions,
    WeightOptions,
} from '../../primitives/heading/Heading';
import usePlatformSectors from '../hooks/usePlatformSectors';
import FormChoice, {
    ChoiceTypeOptions,
} from '../../primitives/form/FormChoice';
import FormChoiceList from '../../primitives/form/FormChoiceList';
import {
    ClientTypeOptions,
    ServiceTypeOptions,
} from '../hooks/usePlatformFiltering';
import { usePlatformOverviewContext } from '../../../context/platformOverview/platformOverviewContext';
import styles from '../platformOverviewFilters.module.scss';

const PlatformFilterForm = () => {
    const { platformSectors } = usePlatformSectors();
    const {
        onSectorsFilterChange,
        onServiceTypesFilterChange,
        onClientTypesFilterChange,
        activeSectors,
        activeClientTypes,
        activeServiceTypes,
    } = usePlatformOverviewContext();

    return (
        <form>
            <Heading
                tag={TagOptions.h5}
                style={StyleOptions.base}
                className={styles.filterCategoryHeading}
            >
                Sector
            </Heading>
            {platformSectors.map(({ id, title, sector: sectors }) => (
                <Fragment key={id}>
                    <Heading
                        tag={TagOptions.h6}
                        style={StyleOptions.base}
                        size={SizeVariationOptions.base}
                        weight={WeightOptions.semiBold}
                        className={styles.filterParentSector}
                    >
                        {title}
                    </Heading>
                    <FormChoiceList>
                        {sectors.map((sector) => (
                            <FormChoice
                                key={sector.id}
                                type={ChoiceTypeOptions.checkbox}
                                name="sector"
                                id={sector.id}
                                value={sector.contentful_id}
                                label={sector.title}
                                onChangeCallback={onSectorsFilterChange}
                                checked={activeSectors.includes(
                                    sector.contentful_id
                                )}
                            />
                        ))}
                    </FormChoiceList>
                </Fragment>
            ))}
            <Heading
                tag={TagOptions.h5}
                style={StyleOptions.base}
                className={styles.filterCategoryHeading}
            >
                Type dienst
            </Heading>
            <FormChoiceList>
                {Object.values(ServiceTypeOptions).map((type) => (
                    <FormChoice
                        key={type}
                        type={ChoiceTypeOptions.checkbox}
                        name="serviceType"
                        id={type}
                        value={type}
                        label={type}
                        onChangeCallback={onServiceTypesFilterChange}
                        checked={activeServiceTypes.includes(type)}
                    />
                ))}
            </FormChoiceList>
            <Heading
                tag={TagOptions.h5}
                style={StyleOptions.base}
                className={styles.filterCategoryHeading}
            >
                Type klant
            </Heading>
            <FormChoiceList>
                {Object.values(ClientTypeOptions).map((type) => (
                    <FormChoice
                        key={type}
                        type={ChoiceTypeOptions.checkbox}
                        name="clientType"
                        id={type}
                        value={type}
                        label={type}
                        onChangeCallback={onClientTypesFilterChange}
                        checked={activeClientTypes.includes(type)}
                    />
                ))}
            </FormChoiceList>
        </form>
    );
};

export default PlatformFilterForm;
