import React from 'react';
import SEO from '../components/primitives/Seo';
import Spotlight from '../components/primitives/spotlight/Spotlight';
import PlatformOverview from '../components/platformOverview/PlatformOverview';

const Home = () => (
    <>
        <SEO title="Home" />
        {/*@todo: import from Contentfull*/}
        <Spotlight
            title="Alle kluseconomie platformen"
            subTitle="Op deze website vind je een compleet overzicht van alle kluseconomie platformen die actief zijn op de Nederlandse markt."
        />
        <PlatformOverview />
    </>
);

export default Home;
