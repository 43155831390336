import React from 'react';
import { PlatformOverviewItem as PlatformOverviewItemModel } from '../../../model/types/platformOverviewTypes';
import PlatformOverviewSectorList from './PlatformOverviewSectorList';
import { Link } from 'gatsby';
import { createPlatformDetailPath } from '../../../routing/urlGenerator';
import styles from '../platformOverview.module.scss';
import Heading, {
    TagOptions,
    StyleOptions,
    WeightOptions,
} from '../../primitives/heading/Heading';
import { navigate } from '@reach/router';

const LOGO_IMAGE_HEIGHT = 64;

type Props = {
    item: PlatformOverviewItemModel;
};

const PlatformOverviewItem: React.FC<Props> = ({
    item: { slug, title, sectors, introduction, logo },
}) => {
    const path = createPlatformDetailPath(slug);

    const onCardClick = () => navigate(path);

    return (
        <article className={styles.card} onClick={onCardClick}>
            {logo && logo.file && logo.file.url && (
                <div className={styles.cardImage}>
                    <img
                        src={`${logo.file.url}?h=${LOGO_IMAGE_HEIGHT}`}
                        alt={logo.title ?? ''}
                        loading="lazy"
                        height={LOGO_IMAGE_HEIGHT}
                    />
                </div>
            )}
            <div className={styles.cardContent}>
                <Heading
                    tag={TagOptions.h3}
                    style={StyleOptions.base}
                    weight={WeightOptions.semiBold}
                    className={styles.cardTitle}
                >
                    <Link to={path}>{title}</Link>
                </Heading>
                <p className={styles.cardDescription}>{introduction}</p>
                <footer className={styles.cardFooter}>
                    {Array.isArray(sectors) && (
                        <div className={styles.headerSectors}>
                            <PlatformOverviewSectorList sectors={sectors} />
                        </div>
                    )}
                </footer>
            </div>
        </article>
    );
};

export default PlatformOverviewItem;
