import React from 'react';
import ReactJSPagination from 'react-js-pagination';
import { PaginatedCollection } from '../../../model/types/globalTypes';
import styles from './pagination.module.scss';

export type OnPageChangeCallback = (newPage: number) => void;

type Props<T> = {
    collection: PaginatedCollection<T>;
    currentPage: number;
    onPageChange: OnPageChangeCallback;
};

function Pagination<T>({ collection, currentPage, onPageChange }: Props<T>) {
    if (collection.totalNoOfResults <= collection.noOfResultsPerPage) {
        return null;
    }

    return (
        <ReactJSPagination
            innerClass={styles.pagination}
            activePage={currentPage}
            itemsCountPerPage={collection.noOfResultsPerPage}
            totalItemsCount={collection.totalNoOfResults}
            pageRangeDisplayed={5}
            onChange={onPageChange}
            linkClass={styles.linkClass}
            activeLinkClass={styles.activeLink}
        />
    );
}

export default Pagination;
