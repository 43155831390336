import React from 'react';
import Heading, {
    TagOptions,
    StyleOptions,
    WeightOptions,
    SizeVariationOptions,
} from '../primitives/heading/Heading';
import PlatformFilterForm from './components/PlatformFilterForm';
import { ReactComponent as ToggleArrow } from '../../images/icons/arrow-down.svg';
import styles from './platformOverviewFilters.module.scss';
import createClassName from 'classnames';
import usePlatformFilteringVisibility from './hooks/usePlatformFilteringVisibility';

const PlatformOverviewFilters = () => {
    const { isVisible, toggleVisibility } = usePlatformFilteringVisibility();
    const wrapperClassName = createClassName(styles.wrapper, {
        [styles.isOpen]: isVisible,
    });

    return (
        <div className={wrapperClassName}>
            <Heading
                tag={TagOptions.h4}
                style={StyleOptions.base}
                weight={WeightOptions.semiBold}
                size={SizeVariationOptions.base}
                className={styles.filterHeading}
            >
                <button
                    onClick={toggleVisibility}
                    aria-expanded={isVisible}
                    className={styles.headingToggleButton}
                >
                    <span>Filter</span>
                    <ToggleArrow className={styles.headingToggleIcon} />
                </button>
            </Heading>
            <div hidden={!isVisible}>
                <PlatformFilterForm />
            </div>
        </div>
    );
};

export default PlatformOverviewFilters;
