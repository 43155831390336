import React from 'react';
import styles from '../contentBlock.module.scss';

type Props = {
    children: React.ReactNode;
};

const ContentBlockSideBar = ({ children }: Props) => (
    <aside className={styles.sidebar}>{children}</aside>
);

export default ContentBlockSideBar;
