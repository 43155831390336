import React from 'react';
import createClassName from 'classnames';

type Props = {
    children: React.ReactNode;
    inline?: boolean;
};

const FormChoiceList = ({ children, inline = false }: Props) => {
    const groupClassName = createClassName('form__group form-choice-list', {
        'form-choice-list--inline': inline,
    });

    return <div className={groupClassName}>{children}</div>;
};

export default FormChoiceList;
