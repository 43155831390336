import React from 'react';
import styles from '../platformOverview.module.scss';
import Heading, {
    TagOptions,
    StyleOptions,
} from '../../primitives/heading/Heading';
import { usePlatformOverviewContext } from '../../../context/platformOverview/platformOverviewContext';

type Props = {
    totalNoOfResults: number;
    isLoading: boolean;
};

const PlatformOverviewHeader = ({ totalNoOfResults, isLoading }: Props) => {
    const {
        activeSectors,
        resetAllActiveFilters,
    } = usePlatformOverviewContext();

    return (
        <header className={styles.overviewHeader}>
            {!isLoading && (
                <Heading
                    tag={TagOptions.h2}
                    style={StyleOptions.base}
                    className={styles.overviewHeading}
                >
                    {`${totalNoOfResults} ${
                        totalNoOfResults === 1 ? 'platform' : 'platformen'
                    } gevonden`}
                </Heading>
            )}
            {activeSectors.length > 0 && (
                <button
                    className="button--primary"
                    onClick={resetAllActiveFilters}
                >
                    Wis alle filters
                </button>
            )}
        </header>
    );
};

export default PlatformOverviewHeader;
