export function createSortByItemKey<T>(key: keyof T) {
    return (first: T, second: T) => {
        if (first[key] > second[key]) {
            return 1;
        }

        if (first[key] < second[key]) {
            return -1;
        }

        return 0;
    };
}

export function sortByItemKey<T>(values: T[], key: keyof T): T[] {
    return values.sort(createSortByItemKey<T>(key));
}
