import { usePlatformOverviewContext } from './../../../context/platformOverview/platformOverviewContext';
import { useEffect, useRef } from 'react';

export default function useScrollToTopOfListOnFilterChange() {
    const overviewContainerRef = useRef<HTMLElement | null>(null);

    const {
        activeSectors,
        activeServiceTypes,
        activeClientTypes,
    } = usePlatformOverviewContext();

    useEffect(() => {
        if (!overviewContainerRef.current) {
            return;
        }

        window.scroll({
            top: overviewContainerRef.current.offsetTop,
            left: 0,
            behavior: 'smooth',
        });
    }, [activeSectors, activeServiceTypes, activeClientTypes]);

    return { overviewContainerRef };
}
