import React from 'react';
import { TextBlock, TextRow } from 'react-placeholder/lib/placeholders';
import styles from '../platformOverview.module.scss';

const PlatformOverviewItemPlaceholder: React.FC = () => (
    <div className={styles.card}>
        <div className={styles.cardContent}>
            <TextRow
                color="#dddddd"
                style={{ width: 120, height: 20, marginBottom: 10 }}
            />
            <TextBlock
                rows={3}
                color="#dddddd"
                lineSpacing={3}
                style={{ width: '100%', marginBottom: 20 }}
            />
        </div>
    </div>
);

export default PlatformOverviewItemPlaceholder;
