import React from 'react';
import Pagination from '../primitives/pagination/Pagination';
import { PlatformOverviewItem } from '../../model/types/platformOverviewTypes';
import { usePlatformOverviewContext } from '../../context/platformOverview/platformOverviewContext';
import PlatformOverviewItemComponent from './components/PlatformOverviewItem';
import useScrollToLastScrollPositionOnReturnToOverview from './hooks/useScrollToLastScrollPositionOnReturnToOverview';
import PlatformOverviewList from './components/PlatformOverviewList';
import PlatformOverviewListPlaceholder from './components/PlatformOverviewListPlaceholder';
import PlaceholderWrapper from '../primitives/placeholder/PlaceholderWrapper';
import PlatformOverviewFilters from '../platformOverviewFilters/PlatformOverviewFilters';
import ContentBlock from '../primitives/contentBlock/ContentBlock';
import ContentBlockSideBar from '../primitives/contentBlock/components/ContentBlockSidebar';
import ContentBlockMain from '../primitives/contentBlock/components/ContentBlockMain';
import PlatformOverviewHeader from './components/PlatformOverviewHeader';
import useScrollToTopOfListOnFilterChange from './hooks/useScrollToTopOfListOnFilterChange';

const PlatformOverview = () => {
    const {
        collection,
        isLoading,
        onPageChange,
        currentPage,
    } = usePlatformOverviewContext();

    useScrollToLastScrollPositionOnReturnToOverview();
    const { overviewContainerRef } = useScrollToTopOfListOnFilterChange();

    return (
        <ContentBlock ref={overviewContainerRef}>
            <ContentBlockSideBar>
                <PlatformOverviewFilters />
            </ContentBlockSideBar>
            <ContentBlockMain>
                {!isLoading && (
                    <PlatformOverviewHeader
                        totalNoOfResults={collection.totalNoOfResults}
                        isLoading={isLoading}
                    />
                )}
                <PlaceholderWrapper
                    ready={!isLoading}
                    delay={1000}
                    customPlaceholder={<PlatformOverviewListPlaceholder />}
                >
                    <PlatformOverviewList>
                        {collection.items.map((item) => (
                            <PlatformOverviewItemComponent
                                key={item.id}
                                item={item}
                            />
                        ))}
                    </PlatformOverviewList>
                </PlaceholderWrapper>
                {!isLoading && (
                    <Pagination<PlatformOverviewItem>
                        collection={collection}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                    />
                )}
            </ContentBlockMain>
        </ContentBlock>
    );
};

export default PlatformOverview;
