import React from 'react';
import createClassName from 'classnames';

export enum ChoiceTypeOptions {
    checkbox = 'checkbox',
    radio = 'radio',
}

export type OnChangeCallback = (input: HTMLInputElement) => void;

type Props = {
    type: ChoiceTypeOptions;
    id: string;
    label: string;
    value: string;
    name: string;
    className?: string;
    onChangeCallback: OnChangeCallback;
    checked: boolean;
};

const FormChoice = ({
    type,
    id,
    label,
    value,
    name,
    className,
    onChangeCallback,
    checked,
}: Props) => {
    const wrapperClassName = createClassName(
        'form-choice',
        {
            'form-choice--checkbox': type === ChoiceTypeOptions.checkbox,
            'form-choice--radio': type === ChoiceTypeOptions.radio,
        },
        className
    );

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!onChangeCallback) {
            return;
        }

        onChangeCallback(event.target);
    };

    return (
        <div className={wrapperClassName}>
            <input
                type={type}
                name={name}
                value={value}
                id={id}
                className="form-choice__input"
                onChange={handleOnChange}
                checked={checked}
            />
            <label htmlFor={id} className="form-choice__label">
                {label}
            </label>
        </div>
    );
};

export default FormChoice;
